export const clientNameFooter = "YOGAMAYUR";
export const titleMain = "Namaste";
export const subtitleMain = "";

export const aspectRatioLogoAbout = '2'; // width/height
export const aspectRatioLogoMain = '1'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "GIOVANNA COUTINHO DOS SANTOS";
export const nomeFornecedor = "YOGAMAYUR";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com/gicoutinhoyoga/?hl=pt-br",
    profile: "@gicoutinhoyoga"
  },
  {
    type: "material-community",
    name: "whatsapp",
    link: "https://wa.me/5521964151348?text=Wpp",
    number: "(21) 96415-1348"
  },
];

export const drawerClosedLinks = [
  {
    title: "Fale Comigo",
    link: "https://wa.me/5521964151348?text=Wpp",
  },
];

export const linkAppleStore = "https://apps.apple.com/br/app/yogamayur/id6449791114?l=en"
export const linkPlayStore = "https://play.google.com/store/apps/details?id=app.web.mobile_yogamayur.twa&hl=pt_BR&gl=US"

export const hasOutsideAboutPage = false;
export const drawerOpenedLinks = [];